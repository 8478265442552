import EmptyLayout from "./EmptyLayout";
import SideBarLayout from "./SidebarLayout";

const LayoutConfig = [
    {
        path: "/login",
        Layout: EmptyLayout,
    },
];

const getLayoutConfig = (path) => {
    let config = LayoutConfig.find((configItem) => path.includes(configItem.path));
    if (config) return config;
    else return { Layout: SideBarLayout };
};

export { getLayoutConfig };
